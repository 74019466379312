import { performRequest } from './api-handler'
import { URLS } from '../utility/urls'

export default () => {
    const { GET_TOUR_PACKAGES, GET_ROOM_TYPES, GET_ROOM_NUMBER, 
            GET_ADDITIONAL_PACKAGES, ADD_RESERVATIONS, GET_BOOKING_DETAILS,
            GET_ADDONS_CATEGORY, GET_LOCATION_START_POINTS, 
            GET_LOCATION_END_POINTS, GET_BOOKING_DATA, GET_BOOKING_SUMMARY,
            BLOCK_ROOM_TEMP, GET_INCLUDED_ADDONS, ADD_OFFLINE_PAYMENT,
            UPDATE_RESERVATIONS,
            GET_BOOKING_JSON, GET_BOOKING_SETTING_DETAILS,
            GET_EXCLUDED_BOAT,
            ADD_TRANSPORTATION_NOTES,
            GET_PACKAGE_BY_ID,
            USER_UPDATE_RESERVATIONS,
            GET_TYPEOFPAX_SETTING_DETAILS,
            EDIT_BOOKING_JSON,
            EDIT_RES_BOOKING_JSON,
            ROOM_TIMER,
            UN_BLOCK_ROOM_TEMP,
            EXTEND_ROOM_TIME,
            GET_CUSTOMER_DATA,
            SELECT_ROOM_AUTOMATICALLY,
            GET_PAYMENT_LIST,
            EDIT_RESERVATIONS,
            UPDATE_INCLUDED_TRANSPORTATIONS,
            GET_WALLET_INFORMATION,
            CANCEL_BOOKING,
            GET_PROMOCODE_BY_CODE,
            GET_PACKAGES_DISCOUNT,
            POST_REFERRAL_CLICKS,
            GET_PACKAKE_LIST,
            GET_WALLET_PAYOUT,
            GET_BOOKING_RATES,
            GET_EXCLUDE_CHARGE,
            GET_COMPLETED_BOOKINGS,
            GET_ALL_PACKAGE_LIST,
            ROOMS_AVAILABILITY,
            UPDATE_TRANSPORTATION,
            GET_PREBOOKING_JSON,
            GET_INFLUENCER_PACKAGE,
            CREATE_BOOKING_ENQUIRY
              } = URLS
    return {
        getPackageDiscount: (body={}, method='POST',id) =>
            performRequest(method ? method:'POST', GET_PACKAGES_DISCOUNT.replace(":_id", id), body),
        updateIncludedTransportation:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_INCLUDED_TRANSPORTATIONS.replace(":_id", body._id), body, "userPortal"),
        updateTransportation:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_TRANSPORTATION.replace(":_id", body._id), body),
        getBookingDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_TOUR_PACKAGES+`${body.id? "?userId="+body.id: ""}${body.edit? "&edit="+body.edit: ''}${body.package? "&package="+body.package: ""}`, {}, "userPortal"),
        getPackageInfo: (body={}, method='get',id) =>
            performRequest(method ? method:'get', GET_PACKAGE_BY_ID.replace(":_id", id), body),   
        getRoomTypes: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ROOM_TYPES, body),
        getRoomNumbers: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ROOM_NUMBER, body),
        getAdditionalPackages:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ADDITIONAL_PACKAGES, body),
        postReservations:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_RESERVATIONS, body, "userPortal"),
        putReservations:(body = {}, method = 'post',id) =>
            performRequest(method ? method : 'post', EDIT_RESERVATIONS, body, "userPortal"),
        getBookingDetailsByRoomWise:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_BOOKING_DETAILS, body),
        getAddOnsCategory:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ADDONS_CATEGORY, body),
        getBookingListing:(body = {}, method = 'get',queryParam) =>
            performRequest(method ? method : 'get', `${GET_BOOKING_DATA}`, body, "userPortal"),
        getLocationStartPoints:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_LOCATION_START_POINTS, body),
        getLocationEndPoints:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_LOCATION_END_POINTS.replace(":_id", body._id).replace(":type", body.type), body),
        getBookingSummary:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
            UPDATE_RESERVATIONS.replace(":_id", body._id), body),
        blockRoomTemp:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
            BLOCK_ROOM_TEMP.replace(":_id", body._id), body),
        unBlockRoomTemp:(body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', 
                UN_BLOCK_ROOM_TEMP.replace(":_id", body._id), body),
        getIncludedAddOns:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_INCLUDED_ADDONS, body),
        saveOfflinePayment:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
                ADD_OFFLINE_PAYMENT.replace(":_id", body._id), body),
        updateReservations:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
            USER_UPDATE_RESERVATIONS.replace(":_id", body._id), body),
        getPostjson:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_BOOKING_JSON.replace(":_id", body._id), body, "userPortal"),
        getLeadSources:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_BOOKING_SETTING_DETAILS, body),
        getExcludedBoats:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_EXCLUDED_BOAT, body),
        getPrmoCodeByCode: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PROMOCODE_BY_CODE.replace(":code", body.code), body),
        getPaxDetails:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_TYPEOFPAX_SETTING_DETAILS, body),
        addTransportationNotes:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
                ADD_TRANSPORTATION_NOTES.replace(":_id", body._id).replace(":transId", body.transId), body),
        editBookingJson:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
                EDIT_BOOKING_JSON.replace(":_id", body._id), body, "userPortal"),
        editResBookingJson:(body = {}, method = 'put') =>
                performRequest(method ? method : 'put', 
                    EDIT_RES_BOOKING_JSON.replace(":_id", body._id), body, "userPortal"),        
        roomTimer:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                ROOM_TIMER, body),
        timeExtension:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
                EXTEND_ROOM_TIME, body),
        getCustomerDetails:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get',GET_CUSTOMER_DATA.replace(":_id", body._id), body, "userPortal"),
        getAutomaticallySelectedRoom:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', SELECT_ROOM_AUTOMATICALLY, body, "userPortal"),
        getPaymentDetails:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get',GET_PAYMENT_LIST, body, "userPortal"),
        getWalletDetails:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get',GET_WALLET_INFORMATION, body, "userPortal"),
        cancelBooking:(body = {}, method = 'DELETE') =>
            performRequest(method ? method : 'DELETE',CANCEL_BOOKING.replace(":_id", body._id), body, "userPortal"),
        postReferralClick:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', POST_REFERRAL_CLICKS, body, "userPortal"),
        getPackageList:(body = {}, method = 'get',query) =>
            performRequest(method ? method : 'get',GET_TOUR_PACKAGES+query, body, "userPortal"),
        getWalletPayout: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_WALLET_PAYOUT, body, "userPortal"),
        updateWalletPayout: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_WALLET_PAYOUT, body, "userPortal"),
        getBookingRates: (body={}, method='get',id) =>
            performRequest(method ? method:'get', GET_BOOKING_RATES.replace(":_id", id), body, "userPortal"),
        getExcludeCharge: (body={}, method='get') =>
            performRequest(method ? method:'get', GET_EXCLUDE_CHARGE, body, "userPortal"),
        getCompletedBookings: (body={}, method='get') =>
            performRequest(method ? method:'get', GET_COMPLETED_BOOKINGS, body, "userPortal"),
        getAllPackageList: (body={}, method='get') =>
            performRequest(method ? method:'get', GET_ALL_PACKAGE_LIST, body, "userPortal"),   
        getRoomsAvailability: (body={}, method='post',) =>
            performRequest(method ? method:'post', ROOMS_AVAILABILITY, body), 
        getPreReservations:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', ADD_RESERVATIONS, body, "userPortal"),
        getPreBookingJson:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PREBOOKING_JSON.replace(':_id', body._id), body, "userPortal"),
        getInfluencerPackage:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
            GET_INFLUENCER_PACKAGE.replace(":_id", body.packageId).replace(":agentId", body.influencerId), "", "userPortal"),
        createBookingEnquiry: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', CREATE_BOOKING_ENQUIRY, body, "userPortal"),
        }
}