export const portalData = {
  // Fax Details
  hasFax: true,
  faxNo: "+603 4143 5414",
  
  // Whatsapp details
  hasWhatsApp: true,
  whatsAppNo: "+60197911002",
  whatsAppNoHolderName: "Reservation KL Office",
  hasAltWhatsApp: true,
  altWhatsAppNo: "+60109391002",
  altWhatsAppNoHolderName: "Resort Reception",
  hasAltWhatsApp1: false,
  altWhatsAppNo1: "",
  altWhatsAppNoHolderName1: "",
  hasAltWhatsApp2: false,
  altWhatsAppNo2: "",
  altWhatsAppNoHolderName2: false,
  
  // Phone number details
  hasPhoneNo: true,
  phoneNo: true,
  hasAltPhoneNo: true,
  altPhoneNo: true,
  
  // Telephone number details
  hasTellNo: true,
  tellNo: "+603 4143 8414",
  tellNoHolderName: "",
  hasAltTellNo: true,
  altTellNo: "+603 4149 3414",
  altTellNoHolderName: "",
  hasAltTellNo2: false,
  altTellNo2: "",
  altTellNoHolderName2: "",
  
  // Portal information details
  portalName: "Ombak",
  portalFullName: "Ombak Dive Resort",
  portalShortAddress: "Wangsa Maju Kuala Lumpur",
  portalAddress: "No 32-2 Jalan Metro Wangsa Seksyen 2 53300 Wangsa Maju Kuala Lumpur",
  portalAddressFirstPart: "No 32-2 Jalan Metro Wangsa Seksyen 2 53300",
  portalAddressSecondPart: " Wangsa Maju Kuala Lumpur",
  portalAddressDetails: "No 32-2 Jalan Metro Wangsa Seksyen 2 53300 Wangsa Maju Kuala Lumpur",
  hasPortalRegNo: true,
  portalRegNo: "939501-V",
  
  // Portal Bank Details
  portalBankName: "Maybank",
  portalBankAccHolderName: "OMBAK TRAVEL & TOUR SDN BHD",
  portalBankAccNo: "564155429775",
  hasPortalBankSwiftCode: true,
  portalBankSwiftCode: "MBBEMYKL",
  
  portalEmail: "reservations@ombakdiveresort.com",
  portalTermAndCondUrl: process.env.REACT_APP_PORTAL_TERM_AND_COND_URL,
  portalPromotionPage: process.env.REACT_APP_PORTAL_PROMOTION_PAGE,
  
  // GST details
  hasGst: false,
  gstNo: true,
  
  
  // Website
  portalWebsite: process.env.REACT_APP_PORTAL_WEBSITE,

  // Travoxis Website
  travoxisWebsite: process.env.REACT_APP_TRAVOXIS_WEBSITE,

  // Facebook page link
  facebookMessengerLink: "https://www.facebook.com/OmbakPerhentianIsland",

  // portal S3 base url
  s3AwsUrl: "",

  adminPortalUrl: process.env.REACT_APP_ADMIN_PORTAL_URL,

  // Portal Jetty names
  jettyOne: "Kuala Besut Jetty",
  jettyTwo: "Perhentian Island",
  jettyThree: "",
  jettyOneToTwoTime: "08:00AM",
  jettyTwoToOneTime: "08:00AM",
  ticketCounter: "Seven Sea Holiday, Kuala Besut Jetty"
}