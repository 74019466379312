export const generateAppConfig = () =>{
    let exportResult = {
        appRepoLocation:"http://localhost:3000",
        backendUrl: "http://localhost:3100",
        appBackendUrl : "http://localhost:4040",
        jsReportUrl: "http://localhost:5488"
    };
    if(process.env.REACT_APP_PRODUCTION_MODE ==='TEST'){
        exportResult.appRepoLocation = "https://app.ombak.my";
        exportResult.backendUrl = "https://admin-api.ombak.my";
        exportResult.appBackendUrl = "https://app-api.ombak.my";
        exportResult.jsReportUrl= "https://resort-api.ombak.my";
    }  
    
    if(process.env.REACT_APP_PRODUCTION_MODE ==='PROD'){
        exportResult.appRepoLocation = "https://app.ombak.my";
        exportResult.backendUrl = "https://admin-api.ombak.my";
        exportResult.appBackendUrl = "https://app-api.ombak.my";
        exportResult.jsReportUrl= "https://report.ombak.my";
    }  
    return exportResult;
}